import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';

import { t } from '@/utility/localization';

import Icon from '@/components/npl/Icon';

const SearchBox = ({
  initialValue = '',
  placeholder = t('enter-name-or-email'),
  onChange,
  loading = false,
  customClassNames,
  customInputClassNames,
  onInputBlur = () => {}
}) => {
  const [search, setSearch] = useState(initialValue);

  const debounceChange = useCallback(
    debounce((value) => onChange(value), 500),
    [onChange]
  );

  useEffect(() => {
    setSearch(initialValue);
  }, [initialValue]);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    debounceChange(value);
  };

  const clearSearch = () => {
    onChange('');
    setSearch('');
  };

  return (
    <div
      className={`relative flex items-center rounded-32 bg-npl-neutral-light-alpha-3  px-12 py-8 focus-within:border-1 focus-within:border-black ${customClassNames}`}>
      <Icon name="search-md" fill="#1B1B1859" width={20} height={20} />
      <input
        className={`ml-6 w-full placeholder:text-npl-text-tertiary-on-light bg-transparent text-label-md text-npl-text-icon-on-light-surface-primary focus:outline-none ${customInputClassNames}`}
        placeholder={placeholder}
        onChange={handleChange}
        value={search}
        onBlur={() => {
          onInputBlur && onInputBlur();
        }}
      />

      {loading && (
        <div className="absolute right-8 flex h-full items-center justify-center">
          <Icon
            icon="loading=01"
            className="animate-spin"
            fill="#1B1B1859"
          />
        </div>
      )}
      {search !== '' && (
        <div
          className="absolute right-8 flex h-full items-center justify-center"
          onClick={clearSearch}
          tabIndex={0}
          role="button">
          <Icon
            name="x-circle-filled"
            fill="#1B1B1859"
            width={20}
            height={20}
          />
        </div>
      )}
    </div>
  );
};

SearchBox.propTypes = {
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  customClassNames: PropTypes.string,
  customInputClassNames: PropTypes.string,
  onChange: PropTypes.func,
  onInputBlur: PropTypes.func
};

export default SearchBox;
